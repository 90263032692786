<!-- 设备分析 -->
<template>
  <div class="page_container" v-loading="loading">
    <div class="flex_column">
      <el-row :gutter="12" class="flex mg_top_22">
        <el-col :span="15" class="flex_column mg_right_22 transition-dom" :class="isActive?'width_50':'width_100'"
          v-if="isActive2">
          <!-- 设备类型KPI分析 -->
          <div class="bg-purple flex justify_between align_center">
            <div>设备类型KPI分析</div>
            <div class="clickButton" @click="funAnimate($event)" v-if="isActive">
              <span>展开</span>
              <img src="../../../assets/images/deviceManagement/a11.png" class="mg_left_8">
            </div>
            <div class="clickButton" @click="funAnimate($event)" v-if="!isActive">
              <span>收缩</span>
              <img src="../../../assets/images/deviceManagement/a14.png" class="mg_left_8">
            </div>
            <!-- active为true是展开 -->
          </div>
          <div class="flex flex_column bgc_FFFFFF">
            <!-- select下拉框（上、左侧div） -->
            <div class="flex mg_left_30 mg_top_20">
              <el-cascader class="mg_right_10 " ref="demoCascader" v-model="organizationValue" :options="organizationAr" placeholder="组织选择"
                :props="{multiple: true,checkStrictly:true,value:'ID',label:'Name',children:'children',expandTrigger: 'hover'}" @change="handleChange"
                size="mini" :show-all-levels='false' collapse-tags clearable>
              </el-cascader>
              <el-select v-model="eqtIDValue" clearable  multiple collapse-tags
                placeholder="多选设备类型" class="mg_right_10" size="mini">
                <el-option v-for="item in kpiAnalysisEquipmentTypeSelectList" :key="item.index" :label="item.name"
                  :value="item.value">
                </el-option>
              </el-select>
              <el-select v-model="dtValue" placeholder="选择时间维度" class="mg_right_10 width_110" size="mini">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
              <el-date-picker v-model="value2" class="mg_right_10" type="datetimerange" align="right"
                start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions">
              </el-date-picker>
              <el-button type="primary" @click="Querytest" icon="el-icon-search"
                class="alarmQuerySelectButton flex align_center justify_center">
              </el-button>
            </div>
            <!-- table -->
            <div class="flex flex_column alarmQueryTable alarmQueryMargin fn_size14">
              <el-table :data="typeAnalysisList" class="width_100" :row-style="{height:40+'px'}" :cell-style="cellStyle"
                height="300" border>
                <el-table-column type="index" label="序号" width="60"></el-table-column>
                <el-table-column prop="equipmentCategoryName" label="设备类型名称" min-width=" "></el-table-column>
                <el-table-column prop="name" label="设备类型名称" min-width=" "></el-table-column>
                <el-table-column prop="runStatus" label="运行数量" min-width=" "></el-table-column>
                <el-table-column prop="failStatus" label="故障数量" min-width=" "></el-table-column>
                <el-table-column prop="stopStatus" label="停止数量" min-width=" "></el-table-column>
                <el-table-column prop="PowerOnRate" label="开机率（%）" min-width=" "></el-table-column>
                <el-table-column prop="FaultRate" label="故障率（%）" min-width=" "></el-table-column>
                <el-table-column prop="TimeMobility" label="稼动率" min-width=" "></el-table-column>
                <el-table-column prop="MTTR" label="MTTR（h）" min-width=" "></el-table-column>
                <el-table-column prop="MTBF" label="MTBF（h）" min-width=" "></el-table-column>
                <el-table-column prop="updateTime" label="更新时间" min-width=" "></el-table-column>
              </el-table>
            </div>
            <el-select v-model="eqtKpiValue" @change="selectKpiChange" placeholder="设备类型Kpi指标"
              class="alarmQuerySelect mg_right_30 mg_left_30 mg_top_20" size="mini">
              <el-option v-for="item in kpiAnalysisEquipmentTypeKpiSelectList" :key="item.id" :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
            <!-- echarts图 -->
            <div class="diy_div alarmQueryMargin" :style="{width:(isActive? '900' : '1800') + 'px'}">
              <div id="chartColumn" class="width_100 height_100"></div>
            </div>
          </div>
        </el-col>
        <el-col :span="15" class="flex_column transition-dom" :class="isActive2?'width_50':'width_100'" v-if="isActive">
          <!-- 设备KPI分析 -->
          <div class="bg-purple flex justify_between align_center">
            <div>设备KPI分析</div>
            <div class="clickButton" @click="funAnimate2($event)" v-if="isActive2">
              <span>展开</span>
              <img src="../../../assets/images/deviceManagement/a11.png" class="mg_left_8">
            </div>
            <div class="clickButton" @click="funAnimate2($event)" v-if="!isActive2">
              <span>收缩</span>
              <img src="../../../assets/images/deviceManagement/a14.png" class="mg_left_8">
            </div>
            <!-- active为true是展开 -->
          </div>
          <div class="flex flex_column bgc_FFFFFF">
            <!-- select下拉框（上、左侧div） -->
            <div class="flex mg_left_30 mg_top_20">
              
          <el-cascader class="mg_right_10 width_190" ref="demoCascader1" v-model="organizationValue1" placeholder="设备选择"
            :options="organizationAr1" :props="defaultProps" @change="handleChange1" :show-all-levels='false' collapse-tags
            clearable>
          </el-cascader>
<!-- 
              <el-cascader class="mg_right_10 " ref="demoCascader1" :options="organizationAr1" placeholder="设备选择"
                :props="{multiple: true,value:'ID',label:'Name',children:'children',expandTrigger: 'hover'}"
                @change="handleChange1" size="mini" v-model="organizationValue1" :show-all-levels='false' collapse-tags
                clearable>
              </el-cascader> -->
              <el-select v-model="dtValue1" placeholder="选择时间维度" class="mg_right_10" size="mini">
                <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
              <el-date-picker v-model="value3" class="mg_right_10" type="datetimerange" align="right"
                start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions">
              </el-date-picker>
              <el-button type="primary" @click="Querytest1"
                class="alarmQuerySelectButton flex align_center justify_center" icon="el-icon-search">
              </el-button>
            </div>
            <!-- table -->
            <div class="flex flex_column alarmQueryTable alarmQueryMargin fn_size14">
              <el-table :data="analysisList" class="width_100" :cell-style="cellStyle" height="300" border>
                <el-table-column type="index" label="序号" min-width="50"></el-table-column>
                <el-table-column prop="equipmentID" label="设备编号" min-width=" 77"></el-table-column>
                <!-- <el-table-column prop="companyName" label="公司名称" min-width=" 77"></el-table-column> -->
                <el-table-column prop="equipmentName" label="设备名称" min-width=" 155"></el-table-column>
                <!-- <el-table-column prop="equipmentNumber" label="设备数量" min-width=" 155"></el-table-column> -->
                <el-table-column prop="deiveceStatus" label="设备状态" min-width=" 77"></el-table-column>
                <el-table-column prop="equipmentcategoryName" label="设备类型名称" min-width=" 139"></el-table-column>
                <el-table-column prop="OEE" label="OEE" min-width=" 139"></el-table-column>
                <el-table-column prop="ComprehensiveRate" label="综合效率（%）" min-width=" 68"></el-table-column>
                <el-table-column prop="PowerOnRate" label="开机率（%）" min-width=" 68"></el-table-column>
                <el-table-column prop="PowerOnUtilization" label="开机利用率（%）" min-width=" 68"></el-table-column>
                <!-- <el-table-column prop="TimeMobility" label="稼动率（%）" min-width=" 68"></el-table-column> -->
                <el-table-column prop="FaultRate" label="故障率（%）" min-width=" 68"></el-table-column>
                <el-table-column prop="MTTR" label="MTTR（h）" min-width=" 75"></el-table-column>
                <el-table-column prop="MTBF" label="MTBF（h）" min-width=" 75"></el-table-column>
                <el-table-column prop="updateTime" label="更新时间" min-width=" 139"></el-table-column>
                <!-- <el-table-column prop="unitName" label="单元名称" min-width=" 75"></el-table-column> -->
                <!-- <el-table-column prop="workShopName" label="车间名称" min-width=" 139"></el-table-column>
                <el-table-column prop="lineName" label="产线名称" min-width=" 139"></el-table-column>
                <el-table-column prop="plantName" label="工厂名称" min-width=" 139"></el-table-column>
                <el-table-column prop="groupName" label="集团名称" min-width=" 139"></el-table-column> -->
              </el-table>
            </div>
            <el-select v-model="eqKpiValue" @change="selectKpiChange1" placeholder="设备Kpi指标"
              class="alarmQuerySelect mg_right_30 mg_left_30 mg_top_20" size="mini">
              <el-option v-for="item in kpiAnalysisEquipmentKpiSelectList" :key="item.id" :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
            <!-- echarts图 -->
            <div class="diy_div alarmQueryMargin" :style="{width:(isActive2? '900' : '1800') + 'px'}">
              <div id="chartColumn2" class="width_100 height_100"></div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
  import * as echarts from "echarts";

  import {
    kpiAnalysisOrganizationSelect,
    kpiAnalysisEquipmentTypeAnalysis,
    kpiAnalysisEquipmentTypeAnalysisTrend,
    kpiAnalysisEquipmentTypeSelect,
    kpiAnalysisEquipmentTypeKpiSelect,

    kpiAnalysisEquipmentSelect,
    kpiAnalysisEquipmentAnalysis,
    kpiAnalysisEquipmentAnalysisTrend,
    kpiAnalysisEquipmentKpiSelect,

  } from "@/api/api_deviceManagement";

  import loginVue from '../../login.vue';

  export default {
    data() {
      return {
        value: "",
        value1: [new Date(new Date().toLocaleDateString()), new Date()],
        value2: [new Date(new Date().toLocaleDateString()), new Date()],
        value3: [new Date(new Date().toLocaleDateString()), new Date()],
        activeName: "1",
        time: new Date(),
        date: "",
        visible: false,
        dialogTableVisible: false,
        collapseNames: "",
        isActive: true,
        isActive2: true,
        options: [{
          value: '1',
          label: '日'
        }, {
          value: '2',
          label: '月'
        }],
        options1: [{
          value: '1',
          label: '日'
        }, {
          value: '2',
          label: '月'
        }],
        typeAnalysisList: [],
        analysisList: [],
        kpiAnalysisEquipmentTypeKpiSelectList: [],
        eqtKpiValue: 1,
        kpiSelectData: [],
        kpiAnalysisEquipmentTypeSelectList: [],
        eqKpiValue: 1,
        kpiAnalysisEquipmentKpiSelectList: [],
        permission: {
          "organizationPermission": [{
              "Type": "P",
              "ID": 1
            },
            {
              "Type": "P",
              "ID": 2
            }
          ],
          "operationPermission": {},
          "organizationPermissionAll": [{
              "Type": "C",
              "ID": [
                1
              ]
            },
            {
              "Type": "P",
              "ID": [
                1,
                2
              ]
            },
            {
              "Type": "WS",
              "ID": [
                1,
                2,
                3,
                4
              ]
            },
            {
              "Type": "L",
              "ID": [
                1,
                2,
                3,
                4,
                17,
                18,
                19,
                20
              ]
            },
            {
              "Type": "U",
              "ID": [
                1,
                2,
                3,
                4,
                17,
                18,
                19,
                20,
                33,
                34,
                35,
                36,
                49,
                50,
                51,
                52
              ]
            },
            {
              "Type": "E",
              "ID": [
                100001,
                100002,
                100003,
                100004,
                100017,
                100018,
                100019,
                100020,
                100033,
                100034,
                100035,
                100036,
                100049,
                100050,
                100051,
                100052,
                200001,
                200002,
                200003,
                200004,
                200017,
                200018,
                200019,
                200020,
                200033,
                200034,
                200035,
                200036,
                200049,
                200050,
                200051,
                200052,
                300001,
                300002,
                300003,
                300004,
                300017,
                300018,
                300019,
                300020,
                300033,
                300034,
                300035,
                300036,
                300049,
                300050,
                300051,
                300052,
                400001,
                400002,
                400003,
                400004,
                400017,
                400018,
                400019,
                400020,
                400033,
                400034,
                400035,
                400036,
                400049,
                400050,
                400051,
                400052
              ]
            }
          ],
          "dataPermission": {
            "P": {
              "ID": [
                1,
                2
              ]
            },
            "C": {
              "ID": [
                1
              ]
            },
            "U": {
              "ID": [
                1,
                2,
                3,
                4,
                17,
                18,
                19,
                20,
                33,
                34,
                35,
                36,
                49,
                50,
                51,
                52
              ]
            },
            "E": {
              "ID": [
                100001,
                100002,
                100003,
                100004,
                100005,
                100006,
                100007,
                100008,
                100009,
                100010,
                100011,
                100012,
                100013,
                100014,
                100015,
                100016,
                100017,
                100018,
                100019,
                100020,
                100021,
                100022,
                100023,
                100024,
                100025,
                100026,
                100027,
                100028,
                100029,
                100030,
                100031,
                100032,
                100033,
                100034,
                100035,
                100036,
                100037,
                100038,
                100039,
                100040,
                100041,
                100042,
                100043,
                100044,
                100045,
                100046,
                100047,
                100048,
                100049,
                100050,
                100051,
                100052,
                100053,
                100054,
                100055,
                100056,
                100057,
                100058,
                100059,
                100060,
                100061,
                100062,
                100063,
                100064,
                200001,
                200002,
                200003,
                200004,
                200005,
                200006,
                200007,
                200008,
                200009,
                200010,
                200011,
                200012,
                200013,
                200014,
                200015,
                200016,
                200017,
                200018,
                200019,
                200020,
                200021,
                200022,
                200023,
                200024,
                200025,
                200026,
                200027,
                200028,
                200029,
                200030,
                200031,
                200032,
                200033,
                200034,
                200035,
                200036,
                200037,
                200038,
                200039,
                200040,
                200041,
                200042,
                200043,
                200044,
                200045,
                200046,
                200047,
                200048,
                200049,
                200050,
                200051,
                200052,
                200053,
                200054,
                200055,
                200056,
                200057,
                200058,
                200059,
                200060,
                200061,
                200062,
                200063,
                200064,
                300001,
                300002,
                300003,
                300004,
                300005,
                300006,
                300007,
                300008,
                300009,
                300010,
                300011,
                300012,
                300013,
                300014,
                300015,
                300016,
                300017,
                300018,
                300019,
                300020,
                300021,
                300022,
                300023,
                300024,
                300025,
                300026,
                300027,
                300028,
                300029,
                300030,
                300031,
                300032,
                300033,
                300034,
                300035,
                300036,
                300037,
                300038,
                300039,
                300040,
                300041,
                300042,
                300043,
                300044,
                300045,
                300046,
                300047,
                300048,
                300049,
                300050,
                300051,
                300052,
                300053,
                300054,
                300055,
                300056,
                300057,
                300058,
                300059,
                300060,
                300061,
                300062,
                300063,
                300064,
                400001,
                400002,
                400003,
                400004,
                400005,
                400006,
                400007,
                400008,
                400009,
                400010,
                400011,
                400012,
                400013,
                400014,
                400015,
                400016,
                400017,
                400018,
                400019,
                400020,
                400021,
                400022,
                400023,
                400024,
                400025,
                400026,
                400027,
                400028,
                400029,
                400030,
                400031,
                400032,
                400033,
                400034,
                400035,
                400036,
                400037,
                400038,
                400039,
                400040,
                400041,
                400042,
                400043,
                400044,
                400045,
                400046,
                400047,
                400048,
                400049,
                400050,
                400051,
                400052,
                400053,
                400054,
                400055,
                400056,
                400057,
                400058,
                400059,
                400060,
                400061,
                400062,
                400063,
                400064
              ]
            },
            "G": {
              "ID": [
                1
              ]
            },
            "WS": {
              "ID": [
                1,
                2,
                3,
                4
              ]
            },
            "L": {
              "ID": [
                1,
                2,
                3,
                4,
                17,
                18,
                19,
                20
              ]
            }
          }
        },
        defaultProps: {
          label: 'Name',
          value: 'ID',
          expandTrigger: 'hover',
          multiple: true, //多选
          emitPath: false, //只返回选中节点的值
          // checkStrictly: true //多选任一级
        },
        currentData1:[],
        standardData:[],
        nameList: [],
        organizationValue: [],
        organizationValue1: [],
        organizationAr: [],
        organizationAr1: [],
        orgTreeData: [],
        orgTreeData1: [],
        standardArr: [],
        standardArr1: [],
        checkData: {},
        checkData1: {},
        tkValue: "1",
        tkValue1: "1",
        eqtIDValue: [],
        dtValue: "1",
        eqIDValue: [],
        dtValue1: "1",
        nowID: "",
        nowID1: "",
        organization: {},
        loading:true,
        equipmentArr:[],
      };
    },
    mounted() {
       if (localStorage.getItem('permission') != null) {
       // console.log(JSON.parse(localStorage.getItem('permission')));
          this.permission = JSON.parse(localStorage.getItem('permission'))
       }
       if (localStorage.getItem('userInfo') != null) {
          let userInfo = JSON.parse(localStorage.getItem('userInfo'))
          this.userID = userInfo.userID
       }
      // this.kpiAnalysisEquipmentTypeAnalysis();
      // this.kpiAnalysisEquipmentTypeAnalysisTrend();
      // this.kpiAnalysisEquipmentAnalysis();
      // this.kpiAnalysisEquipmentAnalysisTrend();

      this.kpiAnalysisOrganizationSelect();
      // this.kpiAnalysisEquipmentTypeSelect();
      this.kpiAnalysisEquipmentTypeKpiSelect();
      this.kpiAnalysisEquipmentSelect();
      this.kpiAnalysisEquipmentKpiSelect();
    },
    methods: {
       //loading 
      getData(){
      const loading = this.$loading({
              lock: true,//lock的修改符--默认是false
              text: 'Loading',//显示在加载图标下方的加载文案
              spinner: 'el-icon-loading',//自定义加载图标类名
              background: 'rgba(0, 0, 0, 0.7)',//遮罩层颜色
              target: document.querySelector('#table')//loadin覆盖的dom元素节点
      });
      loading.close() //成功回调函数停止加载
      },

      Querytest() {
        this.kpiAnalysisEquipmentTypeAnalysis();
        this.kpiAnalysisEquipmentTypeAnalysisTrend();
      },
      Querytest1() {
        this.kpiAnalysisEquipmentAnalysis();
        this.kpiAnalysisEquipmentAnalysisTrend();

      },
      //设备类型
      async kpiAnalysisEquipmentTypeAnalysis() {

        this.checkData.CategoryID = this.eqtIDValue
        // this.checkData.CategoryID = [1]
        var param = {
          organization: this.checkData,
          // startDate: this.value2[0],
          // endDate: this.value2[1],
          dateType: this.dtValue,
          permission: this.permission
        };
        if (this.value2 != null) {
          param.startDate = this.value2[0]
          param.endDate = this.value2[1]
        }
        const selectRes = await kpiAnalysisEquipmentTypeAnalysis(param);
        this.typeAnalysisList = selectRes.data
      },
      async kpiAnalysisEquipmentTypeAnalysisTrend() {
        this.checkData.CategoryID = this.eqtIDValue
        var param = {
          organization: this.checkData,
          // startDate: this.value2[0],
          // endDate: this.value2[1],
          dateType: this.dtValue,
          permission: this.permission,
          typeKpi: this.eqtKpiValue
        };
        if (this.value2 != null) {
          param.startDate = this.value2[0]
          param.endDate = this.value2[1]
        }
        const selectRes = await kpiAnalysisEquipmentTypeAnalysisTrend(param);
        var queryData = selectRes.data;
        var line = {
          legend: {
            type:"scroll",
            data: [],
            bottom: '2%',
            
            textStyle: {
              color: '#808080',
              fontSize: 12,
              rotate: 0,
            },
            
          },
          xAxis: {
            type: "category",
            boundaryGap: true,
            axisTick: {
              alignWithLabel: true, //x轴左右留白
            },
            axisLine: {
              lineStyle: {
                color: "#E3E4E6",
              },
            },
            axisLabel: {
              interval: 0,
              color: "#808080",
              fontSize: 12,
              rotate: 60,
            },
            data: [],
          },
          series: []
        };
        line.xAxis.data = queryData[0].time;
        for (var i = 0; i < queryData.length; i++) {
          var curObj = {
            type: 'line',
            symbol: "circle",
            symbolSize: 8, //拐点圆的大小
          };
          curObj.name = queryData[i].name
          if (this.eqtKpiValue == "1") {
            curObj.data = queryData[i].OperationRateList
          } else if (this.eqtKpiValue == "2") {
            curObj.data = queryData[i].TimeMobilityList
          } else if (this.eqtKpiValue == "3") {
            curObj.data = queryData[i].FaultRateList
          } 
          line.series[i] = curObj
          line.legend.data[i] = queryData[i].name
        }
        this.drawShape(line);
      },

      //设备
      async kpiAnalysisEquipmentAnalysis() {
        console.log(this.currentData);
        var param = {
          organization: {
            'EquipmentID': this.currentData1
          },
          // startDate: this.value2[0],
          // endDate: this.value2[1],
          dateType: this.dtValue1,
          permission: this.permission,
        };
        if (this.value2 != null) {
          param.startDate = this.value2[0]
          param.endDate = this.value2[1]
        }
        const selectRes = await kpiAnalysisEquipmentAnalysis(param);
        this.analysisList = selectRes.data
      },
      async kpiAnalysisEquipmentAnalysisTrend() {
        var param = {
          organization: {
            "EquipmentID": this.currentData1
          },
          // startDate: this.value3[0],
          // endDate: this.value3[1],
          dateType: this.dtValue1,
          permission: this.permission,
          typeKpi: this.eqKpiValue
        };
        if (this.value3 != null) {
          param.startDate = this.value3[0]
          param.endDate = this.value3[1]
        }
        const selectRes = await kpiAnalysisEquipmentAnalysisTrend(param);
        var queryData1 = selectRes.data;
        var line2 = {
          
          legend: {
              type:"scroll",
            data: [],
            bottom: '2%',
            textStyle: {
              color: '#808080',
              fontSize: 12,
              rotate: 0,
            }
          },
  //          dataZoom: [{
  //     type: 'slider',
  //     show: true,
  //     xAxisIndex: [0],
  //     left: '9%',
  //     bottom: -5,
  //     start: 10,
  //     end: 90 //初始化滚动条
  // }],
          xAxis: {
            type: 'category',
            data: [],
            axisTick: {
              alignWithLabel: true
            },
            axisLine: {
              lineStyle: {
                color: '##E3E4E6',
              }
            },
            axisLabel: {
              interval: 0,
              color: '#808080',
              fontSize: 12,
              rotate: 60,
            }
          },
          series: []
        };
        line2.xAxis.data = queryData1[0].time;
        for (var i = 0; i < queryData1.length; i++) {
          var curObj = {
            type: 'line',
            symbol: "circle",
            symbolSize: 8, //拐点圆的大小
            smooth: true,
          };
          curObj.name = queryData1[i].name
          if (this.eqKpiValue == "1") {
            curObj.data = queryData1[i].OEEList
          } else if (this.eqKpiValue == "2") {
            curObj.data = queryData1[i].OperationRateList
          } else if (this.eqKpiValue == "3") {
            curObj.data = queryData1[i].UseRatio
          } else if (this.eqKpiValue == "4") {
            curObj.data = queryData1[i].FaultRateList
          } else {
            curObj.data = queryData1[i].EfficencyList
          }
          line2.series[i] = curObj
          line2.legend.data[i] = queryData1[i].name
        }
        // console.log(line2);
        this.drawShape2(line2);
      },

      //图
      drawShape(line) {
        echarts.init(document.getElementById("chartColumn")).dispose(); //销毁echarts
        /**数据集折线图 */
        this.chartColumn = echarts.init(document.getElementById("chartColumn"));
        this.chartColumn.setOption({
          title: {},
          // 提示框
          tooltip: {
           
          },
          color: ['#6395F9', '#62DAAB', '#657798', '#F6C022'],
          legend: line.legend,
          grid: {
            //设置中间内容距离div的距离，也就是内边距
            left: "3%",
            right: "4%",
            bottom: "10%",
            containLabel: true,
            /* width: 'auto', height: 'auto', */
          },
          xAxis: line.xAxis,

          yAxis: {
            type: 'value',
            // min: 0, //y轴的最小值
            // max: 300, //y轴最大值
            // interval: 30, //值之间的间隔
          },
          series: line.series,
        });
      },
      drawShape2(line2) {
        /**数据集柱状图 */
        echarts.init(document.getElementById('chartColumn2')).dispose(); //销毁echarts
        this.chartColumn = echarts.init(document.getElementById("chartColumn2"));
        this.chartColumn.setOption({
          title: {},
          // 提示框
          tooltip: {},
          // legend: line.legend,"产线17           "
          color: ['#6395F9', '#62DAAB', '#657798', '#F6C022'],
          legend: line2.legend,
          grid: {
            //设置中间内容距离div的距离，也就是内边距
            left: "3%",
            right: "3%",
            bottom: "10%",
            containLabel: true,
            /* width: 'auto', height: 'auto', */
          },
          xAxis: line2.xAxis,

          yAxis: {
            // type
            // min: 0, //y轴的最小值
            // max: 1000, //y轴最大值
            // interval: 100, //值之间的间隔
          },
          series: line2.series,
        });
      },
      funAnimate(el) {
        this.kpiAnalysisEquipmentTypeAnalysisTrend();
        this.isActive = !this.isActive;
        if (this.isActive) {
          //isActive为true时，按钮为展开，两个echarts图都展示
          setTimeout(() => {
            this.kpiAnalysisEquipmentTypeAnalysisTrend();
            this.kpiAnalysisEquipmentAnalysisTrend();
          }, 100);
        } else {
          //isActive为false时，按钮为收缩，只显示第一个echarts图
          setTimeout(() => {
            this.kpiAnalysisEquipmentTypeAnalysisTrend();
          }, 200);
        }
      },
      funAnimate2(el) {
        this.isActive2 = !this.isActive2;
        if (this.isActive2) {
          //isActive2为true时，按钮为展开，两个echarts图都展示
          setTimeout(() => {
            this.kpiAnalysisEquipmentTypeAnalysisTrend();
            this.kpiAnalysisEquipmentAnalysisTrend();
          }, 100);
        } else {
          //isActive2为false时，按钮为收缩，只显示第二个echarts图
          setTimeout(() => {
            this.kpiAnalysisEquipmentAnalysisTrend();
          }, 200);
        }
      },
      cellStyle(row, column, rowIndex, columnIndex) {
        /* 设备状态 */
        if (
          row.column.label === "设备状态" &&
          row.row.equipmentStatus === "运行"
        ) {
          return "background:#56DC28";
        } else if (
          row.column.label === "设备状态" &&
          row.row.equipmentStatus === "待机"
        ) {
          return "background:#FEFC55";
        } else if (
          row.column.label === "设备状态" &&
          row.row.equipmentStatus === "停机"
        ) {
          return "background:#CCCACA";
        } else if (
          row.column.label === "设备状态" &&
          row.row.equipmentStatus === "故障"
        ) {
          return "background:#EE2C3B";
        }
        /* 开机率 */
        if (row.column.label === "开机率（%）" && row.row.powerOnRate > 80) {
          return "background:#56DC28";
        } else if (
          row.column.label === "开机率（%）" &&
          row.row.powerOnRate > 70 &&
          row.row.powerOnRate <= 80
        ) {
          return "background:#FEFC55";
        } else if (
          row.column.label === "开机率（%）" &&
          row.row.powerOnRate < 70
        ) {
          return "background:#EE2C3B";
        }
        /* 开机率 */
        if (row.column.label === "移动率（%）" && row.row.movementRate > 80) {
          return "background:#56DC28";
        } else if (
          row.column.label === "移动率（%）" &&
          row.row.movementRate > 70 &&
          row.row.movementRate <= 80
        ) {
          return "background:#FEFC55";
        } else if (
          row.column.label === "移动率（%）" &&
          row.row.movementRate < 70
        ) {
          return "background:#EE2C3B";
        }
        /* 开机率 */
        if (row.column.label === "故障率（%）" && row.row.failtRate > 10) {
          return "background:#56DC28";
        } else if (
          row.column.label === "故障率（%）" &&
          row.row.failtRate >= 5 &&
          row.row.failtRate < 10
        ) {
          return "background:#FEFC55";
        } else if (row.column.label === "故障率（%）" && row.row.failtRate < 5) {
          return "background:#B8FB7C";
        }
      },

      //组织选择变动
      handleChange() {
        const obj = this.$refs['demoCascader'].getCheckedNodes()
        var currentData = {
          Type: [],
          ID: []
        };
        for (var i = 0; i < obj.length; i++) {
          var authorityadmin = this.standardArr.filter(itmer => itmer.Type == obj[i].data.Type);
          if (authorityadmin.length == 0){
            this.$message({
              message: '警告哦，您没有权限选择此项',
              type: 'warning'
            });
            this.organizationValue = []; //清空选项
            return
          }
          currentData.Type.push(authorityadmin[0].Type);
          var idList = authorityadmin[0].ID;
          for (var j = 0; j < idList.length; j++) {
            var authorityadminTwo = idList.filter(itmer => itmer == obj[i].data.ID);
            if (authorityadminTwo.length == 0) {
              this.$message({
                message: '警告哦，您没有权限选择此项',
                type: 'warning'
              });
              this.organizationValue = [];
              return
            } else {
              for (var z = 0; z < currentData.Type.length; z++) {
                if (authorityadmin[0].Type != currentData.Type[z]) { //只能选择同一级的选项
                  this.$message({
                    message: '警告哦，请相同级别选项',
                    type: 'warning'
                  });
                  this.organizationValue = [];
                  return
                }
              }
              currentData.ID.push(authorityadminTwo[0])
              break
            }
          }
          // }
        }
        
        var testData = {
          Type: '',
          ID: []
        }
        testData.Type = currentData.Type[0];
        testData.ID = currentData.ID;
        this.checkData = testData;
        console.log(this.checkData);
        
      },
      handleChange1() {
        this.currentData1 = this.organizationValue1 
        // console.log(this.organizationValue1);
        

        // this.kpiAnalysisEquipmentAnalysis()
        // this.kpiAnalysisEquipmentAnalysisTrend()
      },

      //组织选择
      async kpiAnalysisOrganizationSelect() {
        var param = {
          permission: this.permission
        };
        const selectRes = await kpiAnalysisOrganizationSelect(param);
        this.organizationAr = selectRes.data.Tree;
        this.standardArr = selectRes.data.organizationPermissionAll;
        this.checkData = selectRes.data.organizationPermissionAll[0];
        // this.organizationValue = selectRes.data.organizationPermissionAll[0].ID;
        this.kpiAnalysisEquipmentTypeSelect()
        // this.kpiAnalysisEquipmentTypeKpiSelect()
        this.kpiAnalysisEquipmentTypeAnalysis()
        this.kpiAnalysisEquipmentTypeAnalysisTrend()
        this.loading = false;
      },

      //设备Type选择
      async kpiAnalysisEquipmentTypeSelect() {
        var param = {
          organization: this.checkData
        };
        const selectRes = await kpiAnalysisEquipmentTypeSelect(param);
        this.kpiAnalysisEquipmentTypeSelectList = selectRes.data
        
      },

      //设备Type KPI指标选择框
      async kpiAnalysisEquipmentTypeKpiSelect() {
        var param = {
          organization: {}
        };
        const selectRes = await kpiAnalysisEquipmentTypeKpiSelect(param);
        this.kpiAnalysisEquipmentTypeKpiSelectList = selectRes.data.Kpi
      },
      //设备Type kpi指标变动反馈
      selectKpiChange() {
        // this.kpiAnalysisEquipmentTypeAnalysis()
        this.kpiAnalysisEquipmentTypeAnalysisTrend();
      },

      //设备选择
      async kpiAnalysisEquipmentSelect() {
        var param = {
          permission: this.permission
        };
        const selectRes = await kpiAnalysisEquipmentSelect(param);
        this.organizationAr1 = selectRes.data.Tree
        this.equipmentArr = selectRes.data.organizationPermissionAll
        this.checkData1 = selectRes.data.organizationPermissionAll[0];
        for (var i = 0; i < this.equipmentArr.length; i++) {
          if (this.equipmentArr[i].Type == 'E') {
            // this.organizationValue1 = this.equipmentArr[i].ID;
            this.currentData1 = this.equipmentArr[i].ID;
            this.standardData = this.equipmentArr[i].ID;
          }
        }
        // console.log('wo',this.standardData,this.currentData1);
        // this.organizationValue1 = selectRes.data.organizationPermission[0].ID;
        this.kpiAnalysisEquipmentAnalysis()
        // this.kpiAnalysisEquipmentKpiSelect()
        this.kpiAnalysisEquipmentAnalysisTrend()

      },
      //设备KPI指标选择框
      async kpiAnalysisEquipmentKpiSelect() {
        var param = {
          organization: {}
        };
        const selectRes = await kpiAnalysisEquipmentKpiSelect(param);
        this.kpiAnalysisEquipmentKpiSelectList = selectRes.data.Kpi

      },
      //设备kpi 指标变动
      selectKpiChange1() {
        // this.kpiAnalysisEquipmentAnalysis()
        this.kpiAnalysisEquipmentAnalysisTrend();
      },
    },
  };
</script>

<style>
  /*修改popper-class格式 popper-class .el-popover.my-popover 这种格式，前缀是.el-popover 不然不生效。且不能加上scoped */
  .el-popover.my-popover {
    top: 425px !important;
  }
</style>

<style scoped>
  .alarmQuerySelect {
    width: 150px;
    height: 36px;
  }

  .alarmQuerySelectButton {
    width: 50px;
    height: 36px;
    background: #009bfd;
  }

  .alarmQueryTable {
    height: 441px;
    margin-right: 31px;
  }

  .alarmQueryMargin {
    margin-left: 31px;
    margin-top: 19px;
  }

  .diy_div {
    /*  width: 920px; */
    height: 400px;
  }

  .divMargin1 {
    margin: 16px 18px 0px 18px;
  }

  .divMargin2 {
    margin: 16px 18px 10px;
  }

  .diy_col {
    height: 63vh;
    margin-bottom: 14px;
  }

  .diy_col2 {
    height: 61vh;
    margin-bottom: 14px;
  }

  .diy_col1 {
    height: 40vh;
    margin-bottom: 14px;
  }

  .bg-purple {
    background: #e2f0fe;
    border: 1px solid #ccdbe5;
    height: 32px;
    line-height: 32px;
    display: flex;
    /* align-items: center; */
    color: #000000;
    font-size: 16px;
    font-weight: 500;
    padding-left: 20px;
  }

  .height_vh {
    height: 280px;
  }

  .height_vh1 {
    height: 280px;
  }

  .height_vh2 {
    height: 345px;
  }

  .el-row {
    margin-right: 0px !important;
  }

  .height_380 {
    height: 380px;
  }

  .clickButton {
    height: 32px;
    width: 70px;
    color: #ffffff;
    background-color: #009bfd;
  }

  .downUp {
    height: 32px;
  }

  .transition-dom {
    transition: all 0.2s linear 0s;
    margin: 0px;
  }
</style>

<style lang="less" scoped>
  // ::v-deep .el-input {
  //   // width: 146px;
  // }

  ::v-deep .el-input__inner {
    height: 36px;
    line-height: 36px;
  }

  /* ::v-deep .el-input--mini .el-input__inner {
        height: 36px;
        line-height: 36px;
    } */

  ::v-deep .el-tabs__item {
    width: 80px;
    /* color: #999999; */
    padding: 0;
    font-size: 16px;
    height: 54px;
    line-height: 54px;
  }

  ::v-deep .el-tabs__item.is-active {
    color: #0099ff;
    font-weight: 500;
  }

  ::v-deep .el-badge__content.is-fixed {
    top: 15px;
    right: 6px;
  }
</style>