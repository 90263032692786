
<template>
  <div class="bg">
    
    <div style="display: flex;align-items: center;">
      <div class="titile">山东矿机平台</div>
    </div>

    <el-form ref="AccountForm" :model="account" :rules="rules" label-position="left" label-width="0px"
      class="demo-ruleForm login-container">

      <div class="title">用户登录</div>
      <el-form-item prop="username" class="loginForm">
        <el-input placeholder="请输入登录ID" v-model="account.username" auto-complete="off">
          <i slot="prefix" class='inputIcon'>
            <img class="id_icon" src="../assets/images/login/login-icon1.png" alt />
          </i>
        </el-input>
      </el-form-item>
      <el-form-item prop="pwd" class="loginForm">
        <el-input type="password" v-model="account.pwd" :autofocus="pwdFocus" auto-complete="off" placeholder="请输入密码">
          <i slot="prefix" class='inputIcon'>
            <img class="pwd_icon" src="../assets/images/login/login-icon2.png" alt />
          </i>
        </el-input>
      </el-form-item>
      <el-form-item class="extra-text">
        <div class="mgl_-22" style="display: flex;align-items: center;">
          <!-- <img style="width:22px;height:22px;float: left;" v-if="!rememberMe" @click="rememberMe=!rememberMe"
            src="../assets/images/login/login_unselect.png" alt />
          <img style="width:22px;height:22px;float: left;" v-if="rememberMe" @click="rememberMe=!rememberMe"
            src="../assets/images/login/login_select.png" alt /> -->
          <el-checkbox v-model="rememberMe" size="medium"></el-checkbox>
          <div class="fn_size20 mg_left_10" style="color:#ffffff;text-align: left;">记住密码</div>
        </div>
      </el-form-item>
      <el-form-item class="login_button">
        <el-button type="primary" class="loginbutton" style="background: #409EFF;" :disabled="allowLogin"
          :loading="loading" @click.native.prevent="handleLogin">登录</el-button>
      </el-form-item>
    </el-form>

    <div class="beiAnHao">
      <div @click="link" style="color:white;cursor:pointer">备案号： 鲁ICP备2021034604号-1</div>
    </div>
  </div>
</template>
<script>
  import {
    login,
    platformSettingTitlePicAndContent
  } from "@/api/api_login";

  export default {
    data() {
      var validateAccount = (rules, value, callback) => {
        if (value === '') {
          callback(new Error('请输入账号'));
        } else {
          if (this.account.username !== '') {
            this.account.username = value;
            this.validateCorrect();
          }
          callback();
        }
      };
      var validatePwd = (rules, value, callback) => {
        if (value === '') {
          callback(new Error('请输入密码'));
        } else {
          if (this.account.pwd !== '') {
            this.account.pwd = value;
            this.validateCorrect();
          }
          callback();
        }
      };
      return {
        loading: false,
        account: {
          username: '',
          pwd: ''
        },
        rules: {
          username: [{
              required: true,
              validator: validateAccount,
              trigger: 'change'
            }

          ],
          pwd: [{
            required: true,
            validator: validatePwd,
            trigger: 'change'
          }]
        },
        pwdFocus: false,
        allowLogin: true,
        checked: true,
        rememberMe: false,
      };
    },
    created() {
      let reg_user = JSON.parse(window.localStorage.getItem('register-user'));
      if (reg_user) {
        this.account.username = reg_user.username;
        this.account.pwd = '';
        this.pwdFocus = true;
      }
    },
    mounted() {
      //search获取url中"?"符后的字串  hash返回"#"之后的内容('?modFlag=business&role=1')
      var url = location.hash
      console.log(url)
      var theRequest = new Object()
      if (url.indexOf('?') != -1) {
        var str = url.substr(1) //substr()方法返回从参数值开始到结束的字符串；
        // console.log(str)
        var strs = str.split('&')
        console.log(strs)
        for (var i = 0; i < strs.length; i++) {
          console.log(strs[i].split('=')[0])
          var shuxingName = strs[i].split('=')[0]
          if (shuxingName.indexOf('?') != -1) {
            console.log(shuxingName.split('?')[1])
            theRequest[shuxingName.split('?')[1]] = strs[i].split('=')[1]
          } else {
            theRequest[shuxingName] = strs[i].split('=')[1]
          }

        }
        console.log(theRequest) //此时的theRequest就是我们需要的参数；
        this.account.username = theRequest.id;
        this.account.pwd = theRequest.pwd;
        this.handleLogin()
      }
      localStorage.clear();
      // console.log(this.$router.options.routes)
      let routerL = this.$router.options.routes
      if (routerL.length > 2) {
        this.$router.go(0)
      }

      window.addEventListener('keydown', this.keyDownLogin);
      // localStorage.setItem('routerPath', JSON.stringify(path));
    },
    methods: {
      //备案号链接
      link(){
        window.open('https://beian.miit.gov.cn')
      },
      async handleLogin() {
        let that = this;
        that.loading = true;
        let param = {
          userAccount: this.account.username,
          userPassword: this.account.pwd,
        };
        const res = await login(
          param
        );
        // console.log(res);
        if (res && res.data.loginStatus) {
          window.removeEventListener('keydown',this.keyDownLogin);//移除键盘监听事件
          localStorage.setItem('userInfo', JSON.stringify(res.data.userInfo));
          localStorage.setItem('appKey', res.data.appKey); //appKey
          localStorage.setItem('permission', JSON.stringify(res.data.permission));


          //后期删除-------------------------------------------------
          // let userInfo = JSON.parse(localStorage.getItem('userInfo'));
          // userInfo.deviceManageModify =false;
          // localStorage.setItem('userInfo', JSON.stringify(userInfo));
          //后期删除------------------------^-------------------------

          

          console.log(res.data.permission.pagePermission);
          this.platformSettingTitlePicAndContent(res.data.userInfo.userID);
          // if (res.messageEnable) {
          this.$message({
            message: res.message,
            type: 'success'
          });
          // }
          localStorage.removeItem('routerPath');
          var router = res.data.permission
          // localStorage.setItem('routerPath', JSON.stringify(this.routeData.data));
          localStorage.setItem('routerPath', JSON.stringify(router.pagePermission.route));

          // console.log(localStorage.getItem('routerPath'))
          // that.dynamicRouter.initRouter()
          that.$router.push({
            path: '/'
          }).catch(() => {
            //此处可以添加一个弹窗，例如：
            // alert('请先登录')
          });

        } else {
          this.loading = false;
          // if (res.messageEnable) {
          this.$message.error(res.message);
          // }
        }
      },
      async platformSettingTitlePicAndContent(id) {
        var param = {
          userID: id
        }
        const selectRes = await platformSettingTitlePicAndContent(param);
        localStorage.setItem('companyInfo', JSON.stringify(selectRes.data));
        // console.log(JSON.parse(localStorage.getItem('companyInfo')))
      },
      validateCorrect() {
        if (this.account.pwd.trim().length > 0 && this.account.username.trim().length > 0) {
          this.allowLogin = false;
        } else {
          this.allowLogin = true;
        }
      },
      // 键盘监听
      keyDownLogin(e) {
        //如果是回车则执行登录方法
        if (e.keyCode == 13) {
          // console.log(1111)
          if (!this.allowLogin) {
            this.handleLogin();
          }
        }
      },

    }
  }
</script>
<style scoped>
  body {
    margin: 0px !important;
    /* padding: 0px; */
    height: 100%;
  }

  #app {
    margin: 0px !important;
  }

  .bg {
    margin-top: -76px;
    /* margin-left: -11px; */
    padding-left: -11px;
    padding-right: -8px;
    width: 100vw;
    height: 100vh;
    background-image: url('./../assets/images/login/login_bg.jpg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-family: Source Han Sans SC;
  }


  .header-content {
    position: fixed;
    top: 0;
    width: 100%;
    height: 50px;
    padding: 6px 0;
    border-bottom: 1px solid #ddd;
    box-shadow: 0 0 2px #ddd;
  }

  .header-content .logo-part {
    margin-left: 10px;
    font-size: 18px;
    color: #999;
  }

  .header-content .logo-part img {
    vertical-align: middle;
  }

  .login-container {
    /*box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.06), 0 1px 0px 0 rgba(0, 0, 0, 0.02);*/
    /* -webkit-border-radius: 5px;
    border-radius: 5px;
    -moz-border-radius: 5px;
    background-clip: padding-box;
    margin: 160px auto;
    width: 350px;
    padding: 35px 35px 15px 35px;
    background: #fff;
    border: 1px solid #eaeaea; */
    /* box-shadow: 0 0 25px #cac6c6; */

    /* background: -ms-linear-gradient(top, #ace, #00C1DE); */
    /* IE 10 */
    /* background: -moz-linear-gradient(top, #ace, #00C1DE); */
    /*火狐*/
    /* background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#ace), to(#00C1DE)); */
    /*谷歌*/
    /* background: -webkit-linear-gradient(top, #ace, #00C1DE); */
    /*Safari5.1 Chrome 10+*/
    /* background: -o-linear-gradient(top, #ace, #00C1DE); */
    /*Opera 11.10+*/
    width: 631px;
    height: 437px;
    background-image: url('../assets/images/login/login-box2.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .login-container .title {
    /* width: 147px; */
    /* width: 200px;
    height: 30px; */
    font-size: 32px;
    font-weight: 500;
    color: #009BFD;
    /* line-height: 25px; */
    margin: 38px 0px;
    letter-spacing: 8px;
  }

  .login-container .remember {
    margin: 0px 0px 35px 0px;
    /* 上右下左 */
  }

  .loginForm ::placeholder {
    color: #ffffff;
    font-size: 20px;
    letter-spacing: 3px;
  }


  .extra-text {
    /*记住密码*/
    width: 470px;
    display: flex;
  }

  .login_button {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .loginbutton {
    width: 515px;
    height: 56px;
    background: #409EFF;
    font-size: 24px;
    letter-spacing: 6px;
    border: none;
  }

  .extra-text .el-form-item__content {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    margin-top: -23px;
    color: #59EDFF;
    line-height: auto;
    font-size: 16px;
  }

  .extra-text a {
    font-size: 12px;
    color: #aaa;
  }

  .extra-text a:hover {
    color: #29e;
  }

  .extra-text .reg-text {
    position: absolute;
    top: 4px;
    right: 2px;
  }

  .titile {
    /* width: 519px; */
    width: 550px;
    height: 56px;
    font-size: 46px;
    font-weight: 800;
    color: #FFFFFF;
    /* background: linear-gradient(0deg, #FFFFFF 0%, #B5F5FF 100%); */
    background: linear-gradient(0deg, #FFFFFF 0%, #B7DAFE 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: 3px;
    margin-bottom: 50px;
  }



  .loginForm {
    width: 515px;
    height: 56px;
    background: rgba(89, 224, 255, 0.25);
    border-color: #20A3F5;
    display: flex;
    align-items: center;
  }

  .inputIcon {
    display: flex;
    align-items: center;
    height: 100%;
    margin-left: 20px;
  }
</style>

<style scoped>
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus input:-webkit-autofill,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 1px solid rgba(89, 224, 255, 1);
    -webkit-text-fill-color: #52FFFF;
    border-radius: 6px;
    -webkit-box-shadow: 0 0 0px 1000px #48A2B3 inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  .input:-internal-autofill-selected {
    background-color: #48A2B3 !important;
  }

  .id_icon {
    width: 23px;
    height: 24px;
  }

  .pwd_icon {
    width: 22px;
    height: 23px;
  }

  .mgl_-22 {
    margin-left: -22px;
  }
  .beiAnHao{
    font-size: 18px;
    color: white;
    position: absolute;
    bottom:20px
  }
</style>

<style lang="less" scoped>
  ::v-deep .el-input__inner::placeholder {
    color: #ffffff;
  }

  /* 谷歌 */
  ::v-deep .el-input__inner::-webkit-input-placeholder {
    /* color: #52FFFF; */
    color: #ffffff;
  }

  /* 火狐 */
  ::v-deep .el-input__inner:-moz-placeholder {
    color: #ffffff;
  }

  /*ie*/
  ::v-deep .el-input__inner:-ms-input-placeholder {
    color: #ffffff;
  }

  ::v-deep.el-input--prefix .el-input__inner {
    padding-left: 70px;
    background: rgba(24, 136, 203, 0.3);
    color: #ffffff;
    border-color: rgba(64, 158, 255, 0.6);
    font-size: 20px;
    height: 56px;
    width: 515px;
    letter-spacing: 3px;
  }

  ::v-deep .el-checkbox__inner {
    width: 22px;
    height: 22px;
    background: rgba(24, 136, 203, 0.3);
    border: 1px solid rgba(64, 158, 255, 0.6);
  }

  ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner::after {
    -webkit-transform: rotate(45deg) scaleY(2);
    transform: rotate(45deg) scale(2);
    margin-left: 4px;
    margin-top: 2px;
  }
</style>